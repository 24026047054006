<template>
    <div>
        <div v-if="resourceData.fieldValue === 'AAA Ghost client'">
            <p>Ghost client</p>
        </div>
        <div v-else>
            <div class="text-xs flex justify-between">
                <a
                    :href="`/employees/${resourceData.resource._resource.extendedProps.uuid}/information`"
                    class="hover:text-red-500 employee-link"
                    @click.prevent="goToEmployee(resourceData.resource._resource.extendedProps.uuid)"
                >
                    {{ resourceData.fieldValue }}
                </a>
                <p v-if="resourceData.resource._resource.extendedProps.working_hours > 0" class="text-gray-600">
                    ({{ normalizeTime(resourceData.resource._resource.extendedProps.working_hours) }})
                </p>
                <p v-else class="text-gray-600">
                    (0{{ $t('calendar.h') }})
                </p>
            </div>
            <div class="flex justify-between items-center mt-3 text-xs">
                <p>{{ normalizeTime(resourceData.resource._resource.extendedProps.duration) }}</p>
                <svg fill="currentColor" viewBox="0 0 20 20" class="w-4 h-4 text-gray-500">
                    <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586
                        11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                    />
                </svg>
                <p
                    :class="{ 'text-red-600': resourceData.resource._resource.extendedProps.percentage < 90,
                              'text-green-600': resourceData.resource._resource.extendedProps.percentage > 91
                    }"
                    class="font-semibold"
                >
                    {{ resourceData.resource._resource.extendedProps.percentage }}%
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        resourceData: {
            type:    Object,
            default: () => {},
        },
    },

    methods: {
        goToEmployee(uuid) {
            this.$router.push({ path: `/employees/${uuid}/information` });
        },
    },
};
</script>
