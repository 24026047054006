var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$wait.is('loading')),expression:"$wait.is('loading')"}],staticClass:"card card-f-h time-line-container relative",class:{
        'address-hidden': !_vm.addressVisible,
        'city-hidden': !_vm.cityVisible,
        'client-hidden': !_vm.clientVisible,
        'employees-hidden': !_vm.employeesVisible,
        'services-hidden': !_vm.servicesVisible,
        'note-hidden': !_vm.noteVisible,
        'cant-create-event': !_vm.userCan('create calendar events')
    },attrs:{"element-loading-spinner":"el-custom-spinner"}},[_c('FullCalendar',{ref:"calendar",attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"resourceLabelContent",fn:function(resourceData){return [_c('ResourceLabelContent',{attrs:{"resourceData":resourceData}})]}},{key:"eventContent",fn:function(event){return [(!event.event.allDay && !event.event.extendedProps.pause && event.event.extendedProps.type !== 'absence')?_c('EventContent',{attrs:{"calendarEvent":event,"vue":_vm.vue},on:{"activateDraggable":_vm.activateDraggable}}):_vm._e(),(event.event.extendedProps.type == 'absence')?_c('EventContentAbsence',{attrs:{"calendarEvent":event,"vue":_vm.vue}}):_vm._e(),(event.event.allDay && event.event.extendedProps.type !== 'absence')?_c('EventContentAllDay',{attrs:{"calendarEvent":event}}):_vm._e(),(event.event.extendedProps.pause)?_c('EventContentPauseBreak',{attrs:{"calendarEvent":event,"vue":_vm.vue}}):_vm._e()]}}])}),_c('TimeLineFloatingMenu',{on:{"changeDaysVisibility":_vm.changeDaysVisibility,"previousTimePeriod":_vm.previousTimePeriod,"nextTimePeriod":_vm.nextTimePeriod,"goToToday":_vm.goToToday,"dateRangeChanged":_vm.changeDateRange,"changeCalendarSettings":_vm.changeCalendarSettings}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }