<template>
    <div
        v-loading="$wait.is('loading')"
        element-loading-spinner="el-custom-spinner"
        class="card card-f-h time-line-container relative"
        :class="{
            'address-hidden': !addressVisible,
            'city-hidden': !cityVisible,
            'client-hidden': !clientVisible,
            'employees-hidden': !employeesVisible,
            'services-hidden': !servicesVisible,
            'note-hidden': !noteVisible,
            'cant-create-event': !userCan('create calendar events')
        }"
    >
        <FullCalendar
            ref="calendar"
            :options="calendarOptions"
        >
            <template #resourceLabelContent="resourceData">
                <ResourceLabelContent :resourceData="resourceData" />
            </template>
            <template #eventContent="event">
                <EventContent
                    v-if="!event.event.allDay && !event.event.extendedProps.pause && event.event.extendedProps.type !== 'absence'"
                    :calendarEvent="event"
                    :vue="vue"
                    @activateDraggable="activateDraggable"
                />
                <EventContentAbsence
                    v-if="event.event.extendedProps.type == 'absence'"
                    :calendarEvent="event"
                    :vue="vue"
                />
                <EventContentAllDay v-if="event.event.allDay && event.event.extendedProps.type !== 'absence'" :calendarEvent="event" />
                <EventContentPauseBreak v-if="event.event.extendedProps.pause" :calendarEvent="event" :vue="vue" />
            </template>
        </FullCalendar>

        <TimeLineFloatingMenu
            @changeDaysVisibility="changeDaysVisibility"
            @previousTimePeriod="previousTimePeriod"
            @nextTimePeriod="nextTimePeriod"
            @goToToday="goToToday"
            @dateRangeChanged="changeDateRange"
            @changeCalendarSettings="changeCalendarSettings"
        />
    </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import svLocale from '@fullcalendar/core/locales/sv';
import queryString from 'query-string';
import { mapState } from 'vuex';

import EventContent from './components/EventContent.vue';
import EventContentAllDay from './components/EventContentAllDay.vue';
import EventContentAbsence from './components/EventContentAbsence.vue';
import EventContentPauseBreak from './components/EventContentPauseBreak.vue';
import ResourceLabelContent from './components/ResourceLabelContent.vue';

export default {
    components: {
        FullCalendar,
        EventContent,
        EventContentAllDay,
        EventContentAbsence,
        EventContentPauseBreak,
        ResourceLabelContent,
        TimeLineFloatingMenu: () => import(/* webpackChunkName: "TimeLineCalendar/TimeLineFloatingMenu" */ './components/TimeLineFloatingMenu'),
    },

    data() {
        return {
            vue:              this,
            from:             this.$dayjs(),
            to:               this.$dayjs().add(7, 'day'),
            addressVisible:   window.Cookies.get('timeline_address_visible') !== 'false',
            cityVisible:      window.Cookies.get('timeline_city_visible') !== 'false',
            clientVisible:    window.Cookies.get('timeline_client_visible') !== 'false',
            employeesVisible: window.Cookies.get('timeline_employees_visible') !== 'false',
            servicesVisible:  window.Cookies.get('timeline_services_visible') !== 'false',
            noteVisible:      window.Cookies.get('timeline_note_visible') !== 'false',
            calendarOptions:  {
                stickyHeaderDates:     true,
                stickyFooterScrollbar: true,
                initialView:           'resourceTimeline',
                duration:              { days: 7 },
                hiddenDays:            window.Cookies.get('week_days_visibility') ? JSON.parse(window.Cookies.get('week_days_visibility')) : [],
                resourceOrder:         'title',
                resources:             [],
                events:                this.getResourcesAndEvents,
                slotMinWidth:          145,
                slotDuration:          { days: 1 },
                scrollTime:            '00:00:00',
                resourceAreaWidth:     '170px',
                schedulerLicenseKey:   'CC-Attribution-NonCommercial-NoDerivatives',
                plugins:               [resourceTimelinePlugin, interactionPlugin, resourceTimeGridPlugin],
                locale:                svLocale,
                contentHeight:         'auto',
                // contentHeight: 'calc(100vh - 120px)',
                weekNumbers:           true,
                headerToolbar:         false,
                slotLabelFormat:       dateObject => this.$dayjs(dateObject.date.marker).format('ddd - D MMM, v.ww'),
                dateClick:             this.showModalAddEvent,
                editable:              false,
                eventDragStop:         this.dragStop,
                eventDrop:             this.eventDrop,
            },
        };
    },

    computed: {
        ...mapState('calendar', {
            cacheIndex:           state => state.cacheIndex,
            dummyRefreshVariable: state => state.dummyRefreshVariable,
        }),
        calendarRefreshIndex() {
            return this.$store.state.calendar.calendarRefreshIndex;
        },
    },

    watch: {
        calendarRefreshIndex() {
            this.$refs.calendar.getApi().refetchEvents();
        },
        dummyRefreshVariable() {
            this.$refs.calendar.getApi().refetchEvents();
        },
    },

    methods: {
        async getResourcesAndEvents({ start, end }) {
            if (start < new Date(2001, 0, 1)) return;

            const query = queryString.parse(window.location.search);

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/timeline_resources_events`, {
                params: {
                    cacheIndex:             this.cacheIndex,
                    from:                   this.$dayjs(start).valueOf(),
                    to:                     this.$dayjs(end).subtract(1, 'day').valueOf(),
                    clients_uuid:           query.client ? [query.client].flat() : [],
                    employees_uuid:         query.employee ? [query.employee].flat() : [],
                    services:          query.services ? [query.services].flat() : [],
                    clients_manager_uuid:   query.clients_manager_uuid ? [query.clients_manager_uuid].flat() : [],
                    employees_manager_uuid: query.employees_manager_uuid ? [query.employees_manager_uuid].flat() : [],
                    without_employees:      query.without_employees || 0,
                    cancelled:              query.cancelled || 0,
                    // with_pause:                 1,
                },
            });

            this.calendarOptions.resources = data.resources;
            return [...data.events, ...data.absence];
        },

        changeDateRange(payload) {
            const { from, daysToShow } = payload;
            this.$refs.calendar.getApi().setOption('duration', { days: daysToShow });
            this.$refs.calendar.getApi().gotoDate(new Date(2000, 0, 1));
            this.$refs.calendar.getApi().gotoDate(from.valueOf());

            // this.$refs.calendar.getApi().refetchEvents();
        },

        showModalAddEvent(event) {
            if (!this.userCan('create calendar events')) {
                return;
            }

            // If Ghost Client was selected
            // @Filip
            if (event.resource._resource.id === '-1') {
                this.$store.commit('calendar/showAddNewModal', {
                    startDateSelected: this.$dayjs(event.date),
                });
                return;
            }

            this.$store.commit('calendar/showAddNewModal', {
                startDateSelected:   this.$dayjs(event.date),
                preSelectedEmployee: [event.resource._resource.extendedProps.uuid],
            });
        },

        previousTimePeriod() {
            this.$refs.calendar.getApi().prev();
        },

        nextTimePeriod() {
            this.$refs.calendar.getApi().next();
        },

        goToToday() {
            this.$refs.calendar.getApi().today();
        },

        changeDaysVisibility(event) {
            this.calendarOptions.hiddenDays = event;
            this.$nextTick(() => {
                this.$refs.calendar.getApi().refetchEvents();
            });
        },

        changeCalendarSettings(event) {
            this.addressVisible = event.addressVisible;
            this.cityVisible = event.cityVisible;
            this.clientVisible = event.clientVisible;
            this.employeesVisible = event.employeesVisible;
            this.servicesVisible = event.servicesVisible;
            this.noteVisible = event.noteVisible;

            this.$nextTick(() => {
                this.$refs.calendar.getApi().destroy();
                this.$refs.calendar.getApi().render();
            });
        },

        dragStop() {
            this.calendarOptions.editable = false;
        },

        async eventDrop(info) {
            // @Filip
            const oldResource = info.oldResource?._resource;
            const newResource = info.newResource?._resource;

            const dataParam = {
                delta:            info.delta.days,
                employeeToRemove: !oldResource ? false : (oldResource.id !== '-1' && oldResource.extendedProps.uuid),
                employeeToAdd:    !newResource ? false : (newResource.id !== '-1' && newResource.extendedProps.uuid),
            };

            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/${info.event.extendedProps.uuid}/update_date`, dataParam);

            this.$notify.success({ title: this.$t('common.success') });

            this.$refs.calendar.getApi().refetchEvents();
        },

        activateDraggable() {
            this.calendarOptions.editable = true;
        },
    },
};
</script>
<style lang='scss'>
    @import './TimeLineView.scss';

    .employee-link {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 115px;
        white-space: nowrap;
    }
</style>
